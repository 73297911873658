import React from "react"



const DomainRegistration = () => {
	return (
		<>
			<section className="cad">
				<div className="container pt-4">
					<div className="row">
						<div className="col-12 col-lg-12">
							<h4 className="cadservices font-weight-bold">CAD Services</h4><br />
							<p>Our CAD services include Design & Drafting Services to individuals and companies in sectors like manufacturing, automotive, machine tool, industrial automation, process industry, fabrication, construction, health care, etc.Our range of CAD services include: </p>
							<ul>
								<li>Mechanical design and drafting</li>
								<li>3D CAD solid modeling</li>
								<li>2D drafting</li>
								<li>Paper to CAD conversion</li>
								<li>2D to 3D conversion</li>
								<li>Detailing & drafting services</li>
								<li>Assembly design</li>
								<li>Mechanical 3D rendering</li>
								<li>3D animation</li>
								<li>Walkthrough</li>
								<li>Reverse engineering</li>

							</ul>
						</div>
						

								<div className="details col-md-6">
									<p>Our experienced professionals listen to the customer voice and deliver the CAD designs with at most care by focusing on the customer needs and ensure the design standards.</p>
									<p>We have acquired experience close to 92,000 hours in design, drafting, 3D modeling CAD conversion and architectural drafting on various products of the industries like automotive, process industry, construction, health care, etc. Also, we have clocked about 19,000 man-hours on mold making for production tooling and soft-tooling as well.</p>
									<p>Our CAD Drafting Services hourly rate is most competitive in the industry. The pricing structure for CAD drafting is fair, transparent and negotiable. In general, the companies are benefited by about 40% to 60 % on their annual budget by outsourcing the CAD services to us</p>
									<p>Our global reach in UK and Sweden is earned by our meritorious services. Our local team members is UK and Sweden are in handy to you. Our location being in India provides us the time zone advantage for quick turnaround time on projects. We are available for your needs round the clock. Our Design and Drafting Service engineers always ensure timely delivery of models and drawings.</p>
								</div>

								<div className="details col-md-6">
									<p>While you outsource CAD services to India at Innoway Systems, we work with you as a team player and are punctual to meet the time schedules of the CAD services assignment. The output quality of CAD design and drafting service is always delivered without any compromise. Our service will help you to enhance the bottom line of your business and lets you to focus on the core activity.</p>
									<p>CAD Outsource Work or Outsource CAD Drawings or Outsource CAD Design has become a part of the industry of the developed nations in order to increase the focus on research, innovative products and process.</p>
									<p>CAD Engineering Services are modeled to suit customer needs in our business. We are open to outsource your job on short-term and long-term basis.</p>
									<p>When you want to Outsource CAD Drafting Services or CAD services, Innoway Systems CAD Drafting Company is willing to associate with you as your outsource partner.</p>
								</div>
						
					</div>
				</div>
			</section>

		</>
	)
}

export default DomainRegistration

import React from "react"
import { Link } from "gatsby"

const projects = () => {
	return (
		<>
			<section>
				<div className="container pt-4">
					<h1 className="cadservices font-weight-bold text-center">PROJECTS</h1><br />
					<div className="row">
						<div className="col-12 col-md projects">
							<Link to="/services/3d-modeling/" >
								<img src="/img/1.png" id="projects" alt=""></img></Link>
							<p>3D Modeling</p>
						</div>
						<div className="col-6 col-md projects">
							<Link to="/services/2d-drafting/" >
								<img src="/img/2d.jpg" id="projects" alt=""></img></Link>
							<p>2D Drafting</p>
						</div>
						<div className="col-6 col-md projects">
							<Link to="/services/2d-drafting/" >
								<img src="/img/2dlayout.jpg" id="projects" alt=""></img></Link>
							<p>2D Layout</p>
						</div>
						<div className="col-6 col-md projects">

							<img src="/img/WP_20160914_16_43_01_Pro-150x150.jpg" id="projects" alt=""></img>
							<p>Leak Test</p>
						</div>
						<div className="col-6 col-md projects">
							<Link to="/services/3d-modeling/" >
								<img src="/img/2.jpg" id="projects" alt=""></img></Link>
							<p>3D Modeling</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default projects

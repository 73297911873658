import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ServicesPage from "../components/services/index"
const services = ({ path }) => {
  return (
    <Layout>
      <SEO
        title="Design and Drafting Services in coimbatore "
        desc="Our experienced professionals listen to the customer voice and deliver the CAD designs with at most care by focusing on the customer needs and ensure the design standards."
        pathname="/services/"
        keywords="2D-DRAFTING-DETAILING ,SIMULATIONS,Mechanical design and drafting"
      />
      <ServicesPage />
    </Layout>
  )
}

export default services

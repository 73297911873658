import React from "react"
import { Link } from "gatsby"

const projectimages = () => {
	return (
		<>
			<section>
				<div className="container pt-4">
					<div className="row">
						<div className="col-12 col-md">
							<Link to="/services/3d-rendering/">
								<img src="/img/3d-render3.jpg" id="projects" alt=""></img></Link>
							<p>3D Rendering</p>
						</div>
						<div className="col-6 col-md projects">
							<Link to="/services/3d-rendering/">
								<img src="/img/3d-render2.jpg" id="projects" alt=""></img></Link>
							<p>3D Rendering</p>

						</div>
						<div className="col-6 col-md projects">
							<Link to="/services/3d-rendering/">
								<img src="/img/3d-render.jpg" id="projects" alt=""></img></Link>
							<p>3D Rendering</p>
						</div>
						<div className="col-6 col-md projects">
							<Link to="/services/3d-rendering/">
								<img src="/img/3d-render4-150x150.jpg" id="projects" alt=""></img></Link>
							<p>3D Rendering</p>
						</div>
						<div className="col-6 col-md projects">
							<Link to="/services/3d-rendering/">
								<img src="/img/3d-render6.jpg" id="projects" alt=""></img></Link>
							<p>3D Rendering</p>
						</div>
					</div>
				</div>

			</section>
		</>
	)
}

export default projectimages

import React from "react"
import CadServices from "./cadservices"
import Slider from "./slider"
import MoldDomainServices from "./molddomainservices"
import Projects from "./projects"
import Projectimages from "./projectimages"

import PrivacyServices from "./privacyservices"

const ServicesPage = () => {
    return (
        <>
            <Slider />
            <CadServices />
            <MoldDomainServices />
            <PrivacyServices />
            <Projects />
            <Projectimages />
        </>
    )
}

export default ServicesPage

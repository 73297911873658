import React from "react"
import { Link } from "gatsby"



const PrivacyProtection = () => {
	return (
		<>
			<h5 className="text-center font-weight-bold">CAD Services</h5>
			<section className="products-card">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6">
							<div className="card text-center">
								<div className="card-body">
									<h5 className="card-title">3D-MODELING</h5>
									<p className="card-text">3D modeling services are being done with certified team members, they are well experienced in the industry to create a design in quick turnaround time. Innnoway Systems can provide you with excellent 3D modeling services for any project that you have. The training, skills acquired, creativity, experience and technology are able to speed up your product development process through 3D modeling.</p>
									<Link to="/services/3d-modeling/" className="btn btn-danger">See Full Details</Link>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-6">
							<div className="card">
								<div className="card-body text-center">
									<h5 className="card-title">CAD CONVERSION</h5>
									<p className="card-text">Innoway Systems offers CAD conversion services to designers, engineers, independent service providers, consultants, research scholars, freelancers, architectural firms, and engineering firms around the globe. We accept all type of formats from you; we can match all of our mechanical, architectural, civil and structural engineering needs when you outsource CAD conversion to us.</p>
									<Link to="/services/cad-conversion/" className="btn btn-danger">See Full Details</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="products-card">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6">
							<div className="card text-center">
								<div className="card-body">
									<h5 className="card-title">2D-DRAFTING & DETAILING</h5>
									<p className="card-text">We are specialized in 2D drafting and drawing. We draw according to the customer standards. We have the 2D drafting services for every type of design. Below are the general services we offer for 2D drafting:</p>
									<ul className="text-left">	<li>Machine Drawings</li>
										<li>Assembly Drawings</li>
										<li>Preliminary Drawings</li>
										<li>Construction or Working Drawings</li>
										<li>Presentation Drawings</li>
										<li>Manufacturing Drawings</li>
										<li>Fabrication Drawings</li></ul>
									<Link to="/services/2d-drafting/" className="btn btn-danger">See Full Details</Link>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-6">
							<div className="card text-center">
								<div className="card-body">
									<h5 className="card-title">3D RENDERING</h5>
									<p className="card-text">nnoway Systems offers the best 3D model architecture and 3D renderings in the 3D animation services space. Our experienced engineers, architects and drafters can take 3D architectural rendering projects of any size and render the best-quality perspective animations, landscape renderings or 3D floor plans. We also create photomontages (composite photographs) that give photorealistic visualizations of 3D model architecture. The 3D renderings help your industrial product stand out in crowed.</p>
									<Link to="/services/3d-rendering/" className="btn btn-danger">See Full Details</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default PrivacyProtection

import React from "react"


const DomainPricing = () => {
	return (
		<>
			 <section>
      <div className="parallax">
        <img src="/img/crane-handle.jpg" alt=""/>
      <div className="overlay"></div>
      <div className="headercontent">
       <h1>SERVICES</h1>             
      </div>
      </div>  
</section>
		</>
	)
}

export default DomainPricing

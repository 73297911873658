import React from "react"
import { Link } from "gatsby"


const FreeDomainServices = () => {
	return (
		<>
			<h5 className="text-center font-weight-bold">Mold design services</h5>
			<section className="products-card">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6">
							<div className="card text-center">
								<div className="card-body">
									<h5 className="card-title">MOLD DESIGN</h5>
									<p className="card-text">Innoway Systems is one of the reputed tool design house for Plastic components. We are specialized in plastic mould components for automotive industry. We have tooling experience over 19K engineering hours in tool design and tool making. We have experience in handling most of the engineering plastics used in the industry. Our tools are running with near zero down time.</p>
									<Link to="/services/mold-design/" className="btn btn-danger">See Full Details</Link>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-6">
							<div className="card text-center">
								<div className="card-body">
									<h5 className="card-title">SIMULATIONS</h5>
									<p className="card-text">Computational fluid dynamics (CFD) simulation allows you to predict, with confidence, the impact of fluid flows on your product throughout design and manufacturing as well as during end use. The analysis can be used to design and optimize new part and to troubleshoot an existing part. In general fluid dynamics solutions give you valuable insight into your product’s performance.</p>
									<Link to="/services/simulations/" className="btn btn-danger">See Full Details</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


		</>
	)
}

export default FreeDomainServices
